<template>
  <div class="table-responsive">
    <table class="table table-borderless table-vertical-center">
      <thead>
        <tr>
          <th class="head-th--gray">
            {{ $t("COMMISSIONS.TITLE1") }}
          </th>
          <th class="head-th--gray" />
          <th class="head-th--gray">
            {{ $t("COMMISSIONS.TITLE2") }}
          </th>
          <th class="head-th--gray" />
          <th class="head-th--gray">
            {{ $t("COMMISSIONS.TITLE3") }}
          </th>
          <th class="head-th--gray" />
          <th class="head-th--gray">
            {{ $t("COMMISSIONS.TITLE4") }}
          </th>
          <th class="head-th--gray" />
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, i) in showInfo">
          <tr v-bind:key="i">
            <!-- COURSE -->
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.course }}
              </span>
              <span class="text-muted font-weight-bold">
                {{ item.type_course }}
              </span>
            </td>
            <td class="text-center font-size--1rem" v-if="item.amount_course">
              {{ item.amount_course }}%
            </td>
            <td class="text-center" v-else />
            <!-- LODGING -->
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.lodging }}
              </span>
              <span class="text-muted font-weight-bold">
                {{ item.type_lodging }}
              </span>
            </td>
            <td class="text-center font-size--1rem" v-if="item.amount_lodging">
              {{ item.amount_lodging }}%
            </td>
            <td class="text-center" v-else />
            <!-- Services -->
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.services }}
              </span>
              <span class="text-muted font-weight-bold">
                {{ item.type_service }}
              </span>
            </td>
            <td class="text-center font-size--1rem" v-if="item.amount_services">
              {{ item.amount_services }}%
            </td>
            <td class="text-center" v-else />
            <!-- Fess -->
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.fees }}
              </span>
              <span class="text-muted font-weight-bold">
                {{ item.type_fee }}
              </span>
            </td>
            <td class="text-center font-size--1rem" v-if="item.amount_fees">
              {{ item.amount_fees }}%
            </td>
            <td class="text-center" v-else />
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      json: null,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },
  mounted() {
    this.FormData();
  },
  methods: {
    FormData() {
      this.json = [
        {
          course: this.$t("COMMISSIONS.COURSE.OPT1"),
          type_course: this.$t("COMMISSIONS.SUBTITLE.OPT1"),
          amount_course: this.data.CourseGeneral,
          lodging: this.$t("COMMISSIONS.ACCOMMODATION.OPT1"),
          type_lodging: null,
          amount_lodging: this.data.AccommodationHomestay,
          services: this.$t("COMMISSIONS.SERVICES.OPT1"),
          type_service: this.$t("COMMISSIONS.SUBTITLE.OPT4"),
          amount_services: this.data.ServiceTransfer,
          fees: this.$t("COMMISSIONS.FEES.OPT1"),
          type_fee: null,
          amount_fees: this.data.FeeRegistration,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT2"),
          type_course: this.$t("COMMISSIONS.SUBTITLE.OPT1"),
          amount_course: this.data.CourseExamPreparation,
          lodging: this.$t("COMMISSIONS.ACCOMMODATION.OPT2"),
          type_lodging: null,
          amount_lodging: this.data.AccommodationResidence,
          services: this.$t("COMMISSIONS.SERVICES.OPT2"),
          type_service: this.$t("COMMISSIONS.SUBTITLE.OPT5"),
          amount_services: this.data.ServiceActivities,
          fees: this.$t("COMMISSIONS.FEES.OPT2"),
          type_fee: null,
          amount_fees: this.data.FeeAccommodation,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT3"),
          type_course: this.$t("COMMISSIONS.SUBTITLE.OPT1"),
          amount_course: this.data.CourseBusiness,
          lodging: this.$t("COMMISSIONS.ACCOMMODATION.OPT3"),
          type_lodging: this.$t("COMMISSIONS.SUBTITLE.OPT3"),
          amount_lodging: this.data.AccommodationFullApartment,
          services: this.$t("COMMISSIONS.SERVICES.OPT3"),
          type_service: this.$t("COMMISSIONS.SUBTITLE.OPT6"),
          amount_services: this.data.ServiceExam,
          fees: this.$t("COMMISSIONS.FEES.OPT3"),
          type_fee: null,
          amount_fees: this.data.FeeCourseMaterial,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT4"),
          type_course: this.$t("COMMISSIONS.SUBTITLE.OPT1"),
          amount_course: this.data.CourseUniversityPreparation,
          lodging: this.$t("COMMISSIONS.ACCOMMODATION.OPT4"),
          type_lodging: this.$t("COMMISSIONS.SUBTITLE.OPT3"),
          amount_lodging: this.data.AccommodationSharedApartment,
          services: this.$t("COMMISSIONS.SERVICES.OPT4"),
          type_service: null,
          amount_services: this.data.ServiceVisa,
          fees: this.$t("COMMISSIONS.FEES.OPT4"),
          type_fee: null,
          amount_fees: this.data.FeeMinor,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT5"),
          type_course: this.$t("COMMISSIONS.SUBTITLE.OPT1"),
          amount_course: this.data.CourseSpecializedLanguage,
          lodging: this.$t("COMMISSIONS.ACCOMMODATION.OPT5"),
          type_lodging: null,
          amount_lodging: this.data.AccommodationHotel,
          services: null,
          type_service: null,
          amount_services: null,
          fees: this.$t("COMMISSIONS.FEES.OPT5"),
          type_fee: null,
          amount_fees: this.data.FeeOthers,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT6"),
          type_course: this.$t("COMMISSIONS.SUBTITLE.OPT1"),
          amount_course: this.data.CourseTeacherTraining,
          lodging: null,
          type_lodging: null,
          amount_lodging: null,
          services: null,
          type_service: null,
          amount_services: null,
          fees: null,
          type_fee: null,
          amount_fees: null,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT7"),
          type_course: this.$t("COMMISSIONS.SUBTITLE.OPT1"),
          amount_course: this.data.CourseSeniorPrograms,
          lodging: null,
          type_lodging: null,
          amount_lodging: null,
          services: null,
          type_service: null,
          amount_services: null,
          fees: null,
          type_fee: null,
          amount_fees: null,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT8"),
          type_course: null,
          amount_course: this.data.CourseWorkAndStudy,
          lodging: null,
          type_lodging: null,
          amount_lodging: null,
          services: null,
          type_service: null,
          amount_services: null,
          fees: null,
          type_fee: null,
          amount_fees: null,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT9"),
          type_course: null,
          amount_course: this.data.CourseOneToOne,
          lodging: null,
          type_lodging: null,
          amount_lodging: null,
          services: null,
          type_service: null,
          amount_services: null,
          fees: null,
          type_fee: null,
          amount_fees: null,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT10"),
          type_course: null,
          amount_course: this.data.CourseJuniors,
          lodging: null,
          type_lodging: null,
          amount_lodging: null,
          services: null,
          type_service: null,
          amount_services: null,
          fees: null,
          type_fee: null,
          amount_fees: null,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT11"),
          type_course: this.$t("COMMISSIONS.SUBTITLE.OPT2"),
          amount_course: this.data.CourseWithActivities,
          lodging: null,
          type_lodging: null,
          amount_lodging: null,
          services: null,
          type_service: null,
          amount_services: null,
          fees: null,
          type_fee: null,
          amount_fees: null,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT12"),
          type_course: null,
          amount_course: this.data.CourseHomeTuition,
          lodging: null,
          type_lodging: null,
          amount_lodging: null,
          services: null,
          type_service: null,
          amount_services: null,
          fees: null,
          type_fee: null,
          amount_fees: null,
        },
        {
          course: this.$t("COMMISSIONS.COURSE.OPT13"),
          type_course: null,
          amount_course: this.data.CourseHomeTuitionJuniors,
          lodging: null,
          type_lodging: null,
          amount_lodging: null,
          services: null,
          type_service: null,
          amount_services: null,
          fees: null,
          type_fee: null,
          amount_fees: null,
        },
      ];
    },
  },
  computed: {
    showInfo() {
      return this.json;
    },
  },
};
</script>

<style lang="scss">
.head-th--gray {
  background-color: #f3f6f9;
  border-bottom: 0;
  letter-spacing: 1px;
  font-weight: 600;
  color: #b5b5c3 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.font-size--1rem {
  font-size: 1.1rem !important;
  white-space: nowrap;
}
</style>
