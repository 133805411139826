<template>
  <div class="business-settings-component">
    <!-- BUTTONS -->
    <Buttons
      :btnCancel="false"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="onSubmit"
      :save_loading="save_loading"
    />
    <!-- FORM BUSSINESS -->
    <v-form ref="form" v-model="valid" lazy-validation :disabled="isDisabled">
      <div class="card-custom-bussiness">
        <div class="">
          <b-row>
            <!-- TITLE 1 -->
            <b-col lg="12" md="12" sm="12" cols="12">
              <h5>
                <strong>
                  {{ $t("SETTINGS.USER.BUSSINESS.TITLE1") }}
                </strong>
              </h5>
              <i>
                {{ optionsCompanyType[form.companyTypeId] }}
              </i>
            </b-col>
            <b-col lg="12" md="12" sm="12" cols="12" class="pt-0">
              <v-radio-group v-model="form.bussinessPrivate" row disabled>
                <v-radio
                  :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.PERSON_L')"
                  :value="1"
                />
                <v-radio
                  :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.PERSON_N')"
                  :value="2"
                />
              </v-radio-group>
            </b-col>
            <b-col
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-if="form.bussinessPrivate === 1"
            >
              <v-text-field
                v-model="form.commercial_name"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.COMMERCIAL')"
                :rules="validations.required"
                required
                outlined
                dense
              />
            </b-col>
            <b-col
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-if="form.bussinessPrivate === 1"
            >
              <v-text-field
                v-model="form.legal_name"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.LEGAL_NAME')"
                :rules="validations.required"
                outlined
                dense
              />
            </b-col>
            <b-col
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-if="form.bussinessPrivate === 2"
            >
              <v-text-field
                v-model="form.first_name"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.FIRST_NAME')"
                :rules="validations.required"
                required
                outlined
                dense
              />
            </b-col>
            <b-col
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-if="form.bussinessPrivate === 2"
            >
              <v-text-field
                v-model="form.last_name"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.LAST_NAME')"
                :rules="validations.required"
                outlined
                dense
              />
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <VueGooglePlaces
                v-model="form.address"
                :api-key="key"
                types="address"
                @placechanged="getAddressData"
                class="without-placeholder"
              >
                <v-text-field
                  v-model="form.address"
                  :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.STREET_ADDRESS')"
                  :rules="[
                    validations.address(
                      form.address,
                      form.addressComponent,
                      true,
                      company.address
                    ),
                  ]"
                  required
                  outlined
                  dense
                />
              </VueGooglePlaces>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <v-text-field
                v-model="form.vat"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.ID')"
                :rules="validations.required"
                outlined
                dense
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" md="4" sm="4" cols="12">
              <v-text-field
                v-model="form.phone"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.PHONE')"
                :rules="validations.required"
                required
                outlined
                dense
              />
            </b-col>
            <b-col lg="4" md="4" sm="4" cols="12">
              <v-text-field
                v-model="form.email"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.EMAIL')"
                :rules="validations.emailRequired"
                required
                outlined
                dense
              />
            </b-col>
            <b-col lg="4" md="4" sm="4" cols="12">
              <v-text-field
                v-model="form.websiteUrl"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.WEBSITE')"
                :rules="validations.required"
                required
                outlined
                dense
              />
            </b-col>
            <!-- LOGO -->
            <b-col lg="12" md="12" sm="12" cols="12" v-if="!isDisabled">
              <Profile
                ref="imageProfile"
                :button_text="$t('SETTINGS.USER.BUSSINESS.FIELDS.LOGO')"
                :profile="false"
              />
            </b-col>
          </b-row>
          <!-- GENERAL CONDITIONS -->
          <b-row>
            <b-col lg="12" md="12" sm="12" cols="12">
              <v-btn
                depressed
                color="primary"
                @click="dialog = true"
                class="mr-3"
              >
                {{ $t("SETTINGS.USER.BUSSINESS.ACCEPT") }}
              </v-btn>
              <v-btn
                depressed
                color="primary"
                @click="dialogCommissions = true"
              >
                {{ $t("COMMISSIONS.BTN") }}
              </v-btn>
            </b-col>
            <b-col lg="4" md="4" sm="12" cols="12"> </b-col>
          </b-row>
          <v-row justify="center">
            <v-dialog v-model="dialog" scrollable max-width="900px">
              <v-card>
                <v-card-title>
                  {{ $t("SETTINGS.USER.BUSSINESS.TITLE2") }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 400px">
                  <Terms :conditions="CompanyCondition.conditions" />

                  <h1>
                    {{ $t("SETTINGS.USER.BUSSINESS.ACCEPT2") }}
                    {{ formatDate }}
                  </h1>

                  <div class="d-flex justify-content-end">
                    <v-btn color="primary" @click="dialog = false">
                      {{ $t("GENERAL.CLOSE") }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- MODAL COMMISSIONS -->
          <b-row justify="center">
            <v-dialog v-model="dialogCommissions" scrollable max-width="960px">
              <v-card>
                <v-card-title>
                  {{ $t("COMMISSIONS.TITLE") }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 400px">
                  <Commissions :data="CompanyCommission" />
                  <v-divider></v-divider>
                  <div class="d-flex justify-content-end">
                    <v-btn color="primary" @click="dialogCommissions = false">
                      {{ $t("GENERAL.CLOSE") }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </b-row>
          <!-- PAYMENT -->
          <b-row>
            <b-col lg="12" md="12" sm="12" cols="12">
              {{ $t("SETTINGS.USER.BUSSINESS.TITLE4") }}
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <v-text-field
                v-model="form.iban"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.IBAN')"
                :rules="[validations.biggerNumber(validations.iban, 30)]"
                counter="30"
                outlined
                dense
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    <span class="d-block">
                      {{ $t("SETTINGS.USER.BUSSINESS.TITLE2_INFO2") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <v-text-field
                v-model="form.bic"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.BIC')"
                :rules="[validations.biggerNumber(validations.bic, 11)]"
                counter="11"
                outlined
                dense
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    <span class="d-block">
                      {{ $t("SETTINGS.USER.BUSSINESS.TITLE2_INFO2") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <v-text-field
                v-model="form.aba"
                :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.ABA')"
                :rules="[validations.biggerNumber(validations.aba, 9)]"
                counter="9"
                outlined
                dense
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    <span class="d-block">
                      {{ $t("SETTINGS.USER.BUSSINESS.TITLE2_INFO2") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- transition rules-->
      <div class="card-custom-bussiness">
        <div class="">
          <b-row>
            <!-- TITLE 2 -->
            <b-col lg="12" md="12" sm="12" cols="12">
              <h5>
                <strong>
                  {{ $t("SETTINGS.USER.BUSSINESS.FIELDS.SET_PRICING") }}
                </strong>
              </h5>
            </b-col>

            <b-col lg="12" md="12" sm="12" cols="12">
              <strong>
                {{ $t("SETTINGS.USER.BUSSINESS.FIELDS.WHAT_HAPPENS_DES") }}
              </strong>
              <v-radio-group
                v-model="form.transitionRuleTypeId"
                column
                :rules="validations.required"
              >
                <v-radio
                  :label="
                    $t('SETTINGS.USER.BUSSINESS.FIELDS.WHAT_HAPPENS_OPT1')
                  "
                  :value="1"
                />
                <v-radio
                  :label="
                    $t('SETTINGS.USER.BUSSINESS.FIELDS.WHAT_HAPPENS_OPT2')
                  "
                  :value="2"
                />
                <v-radio
                  :label="
                    $t('SETTINGS.USER.BUSSINESS.FIELDS.WHAT_HAPPENS_OPT3')
                  "
                  :value="3"
                />
              </v-radio-group>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- CANCELATION POLICY -->
      <div class="card-custom-bussiness">
        <div class="">
          <b-row class="mb-0">
            <!-- GENERAL -->
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12">
                  <h5>
                    <strong>
                      {{ $t("SETTINGS.USER.BUSSINESS.TITLE3") }}
                    </strong>
                  </h5>
                </b-col>
                <b-col lg="6" md="6" sm="6" cols="12">
                  <v-textarea
                    v-model="form.standardCancellationPolicies"
                    :label="$t('CAMPUS.GENERAL_CANCELLATION_POLICY')"
                    :rules="validations.required"
                    outlined
                    dense
                  ></v-textarea>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-0">
            <!-- FREE -->
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE14") }}
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="tooltip-info"
                          >
                            <font-awesome-icon icon="info-circle" />
                          </v-btn>
                        </template>
                        <span>{{ $t("CAMPUS.FREE_INFO") }}</span>
                      </v-tooltip>
                    </strong>
                  </h5>
                </b-col>
                <!-- CHECK BOX -->
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <v-checkbox
                    v-model="freeCancellationCheck"
                    class="align-label"
                    :label="$t('CAMPUS.FREE_CANCELLATION_CHECK')"
                    @click="freeCancellationCheckMethod"
                  />
                </b-col>
              </b-row>
              <!-- Nunber of days -->
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <em :class="{ 'text-muted': !isDisabledFree }">
                    {{ $t("SETTINGS.USER.BUSSINESS.POLICY_CANCELLATION") }}
                  </em>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="2" md="2" sm="3" cols="4" class="pb-0">
                  <v-text-field
                    type="number"
                    v-model="form.cancellationPolicy"
                    :suffix="$t('CAMPUS.DAYS')"
                    :disabled="!isDisabledFree"
                    :rules="[
                      validations.policyNumber(
                        form.cancellationPolicy,
                        isDisabledFree
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <v-textarea
                    v-model="form.cancellationPoliciesDescription"
                    :label="$t('SETTINGS.USER.BUSSINESS.POLICY')"
                    :disabled="!isDisabledFree"
                    :rules="[
                      validations.policyText(
                        form.cancellationPoliciesDescription,
                        isDisabledFree
                      ),
                    ]"
                    outlined
                    dense
                  ></v-textarea>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- FREE POSTPONE -->
          <b-row class="mb-0">
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12" class="pt-0">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE15") }}
                    </strong>
                  </h5>
                </b-col>
                <!-- CHECK BOX -->
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <v-checkbox
                    v-model="freePostponementCancellationCheck"
                    class="align-label"
                    :label="$t('CAMPUS.FREE_POSTPONEMENT_CANCELLATION_CHECK')"
                    @click="freePostponementCancellationCheckMethod"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <em :class="{ 'text-muted': !isDisabledFreePostponement }">
                    {{ $t("CAMPUS.FREE_POSTPONE_POLICY") }}
                  </em>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="2" md="2" sm="3" cols="4" class="pb-0">
                  <v-text-field
                    type="number"
                    v-model="form.freePostponePolicy"
                    :suffix="$t('CAMPUS.DAYS')"
                    :disabled="!isDisabledFreePostponement"
                    :rules="[
                      validations.policyNumber(
                        form.freePostponePolicy,
                        isDisabledFreePostponement
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <em :class="{ 'text-muted': !isDisabledFreePostponement }">
                    {{ $t("CAMPUS.FREE_POSTPONEMENT") }}
                  </em>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="2" md="2" sm="3" cols="4" class="pb-0">
                  <v-text-field
                    type="number"
                    v-model="form.postponePolicy"
                    :suffix="$t('CAMPUS.MONTH')"
                    :disabled="!isDisabledFreePostponement"
                    :rules="[
                      validations.policyNumber(
                        form.postponePolicy,
                        isDisabledFreePostponement
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12">
                  <v-textarea
                    v-model="form.postponeGeneralPolicy"
                    :label="$t('CAMPUS.GENERAL_POSTPONE_POLICY')"
                    :disabled="!isDisabledFreePostponement"
                    :rules="[
                      validations.policyText(
                        form.postponeGeneralPolicy,
                        isDisabledFreePostponement
                      ),
                    ]"
                    outlined
                    dense
                  ></v-textarea>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- VISA -->
          <b-row class="mb-0">
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12" class="pt-0">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE18") }}
                    </strong>
                  </h5>
                </b-col>
                <!-- CHECK BOX -->
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <v-checkbox
                    v-model="visaCancellationCheck"
                    class="align-label"
                    :label="$t('CAMPUS.VISA_CANCELLATION_CHECK')"
                    @click="visaCancellationCheckMethod"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12">
                  <v-textarea
                    v-model="form.visaCancellationPolicy"
                    :label="$t('CAMPUS.VISA_CANCELLATION')"
                    :disabled="!isDisabledVisa"
                    :rules="[
                      validations.policyText(
                        form.visaCancellationPolicy,
                        isDisabledVisa
                      ),
                    ]"
                    outlined
                    dense
                  ></v-textarea>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- PAYMENT POLICY -->
      <div class="card-custom-bussiness">
        <div class="">
          <b-row>
            <!-- TITLE -->
            <b-col cols="12">
              <b-row class="mb-6">
                <b-col lg="12" md="12" sm="12" cols="12">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE19") }}
                    </strong>
                  </h5>
                  <div class="info-payment">
                    <p>{{ $t("CAMPUS.PAYMENT_DESCRIPTION") }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- SPECIAL PAYMENT POLICY -->
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE20") }}
                    </strong>
                    <br />
                    <span class="font-weight-b">
                      {{ $t("CAMPUS.PAYMENT_DESCRIPTION_SUB") }}
                    </span>
                  </h5>
                </b-col>
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                  <v-checkbox
                    v-model="form.PaymentPolicy"
                    class="align-label m-0"
                    :label="$t('CAMPUS.PAYMENT_CHECK_SPECIAL')"
                    @click="PaymentPolicyCheckMethod"
                  />
                </b-col>
              </b-row>

              <!-- PaymentPolicySpecialTerm -->
              <b-row class="mb-0">
                <b-col lg="4" md="4" sm="4" cols="12" class="pb-0">
                  <span
                    :class="{ 'text-muted': !isDisabledPaymentPolicy }"
                    class="font-size-1-2"
                  >
                    {{ $t("CAMPUS.PAYMENT_POLICY_TERMS") }}
                  </span>
                  <v-text-field
                    type="number"
                    v-model="form.PaymentPolicyLongTerm"
                    :suffix="$t('CAMPUS.WEEKS')"
                    :disabled="!isDisabledPaymentPolicy"
                    :rules="[
                      validations.policyNumber(
                        form.PaymentPolicyLongTerm,
                        isDisabledPaymentPolicy
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>

              <!-- PaymentPolicyPercent -->
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <span
                    :class="{ 'text-muted': !isDisabledPaymentPolicy }"
                    class="font-size-1-2"
                  >
                    {{ $t("CAMPUS.PAYMENT_POLICY_PERCENTAGE") }}
                  </span>
                  <v-text-field
                    type="number"
                    v-model="form.PaymentPolicyPercent"
                    :suffix="$t('CAMPUS.PERCENTAGE')"
                    :disabled="!isDisabledPaymentPolicy"
                    :rules="[
                      validations.policyPercentage(
                        form.PaymentPolicyPercent,
                        isDisabledPaymentPolicy
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>

              <!-- PaymentPolicyRecurrence -->
              <b-row class="mb-0">
                <!-- TITLE -->
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                  <span
                    :class="{ 'text-muted': !isDisabledPaymentPolicy }"
                    class="font-size-1-2"
                  >
                    {{ $t("CAMPUS.PAYMENT_POLICY_RECURRENCE") }}
                  </span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="
                          isDisabledPaymentPolicy ? 'primary' : 'secondary'
                        "
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="tooltip-info"
                      >
                        <font-awesome-icon icon="info-circle" />
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("CAMPUS.PAYMENT_POLICY_RECURRENCE_TOOLTIP") }}
                      <br />
                      {{ $t("CAMPUS.PAYMENT_POLICY_RECURRENCE_TOOLTIP_2") }}
                    </span>
                  </v-tooltip>
                </b-col>
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0 pt-0">
                  <v-radio-group
                    v-model="form.PaymentPolicyRecurrence"
                    :disabled="!isDisabledPaymentPolicy"
                    :rules="[
                      validations.policyText(
                        form.PaymentPolicyRecurrence,
                        isDisabledPaymentPolicy
                      ),
                    ]"
                  >
                    <v-radio
                      :label="$t('CAMPUS.PAYMENT_POLICY_RECURRENCE_ITEM1')"
                      :value="BEFORE_COURSE"
                    />
                    <v-radio
                      :label="$t('CAMPUS.PAYMENT_POLICY_RECURRENCE_ITEM2')"
                      :value="QUARTERLY_ADVANCE"
                    />
                    <v-radio
                      :label="$t('CAMPUS.PAYMENT_POLICY_RECURRENCE_ITEM3')"
                      :value="MONTHLY_ADVANCE"
                    />
                  </v-radio-group>
                </b-col>
              </b-row>

              <!-- SPECIAL PAYMENT CONDITIONS -->
              <b-row>
                <!-- PaymentPolicySpecial -->
                <b-col cols="12">
                  <b-row class="mb-0">
                    <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                      <v-checkbox
                        v-model="form.PaymentPolicySpecial"
                        class="align-label m-0"
                        @click="PaymentPolicySpecialCheckMethod"
                        :label="$t('CAMPUS.PAYMENT_POLICY_SPECIAL_CONDITION')"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-0">
                    <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                      <span
                        :class="{
                          'text-muted': !isDisabledPaymentPolicySpecial,
                        }"
                        class="font-size-1-2"
                      >
                        {{ $t("CAMPUS.PAYMENT_POLICY_SPECIAL") }}
                      </span>
                      <v-text-field
                        type="number"
                        v-model="form.PaymentPolicySpecialPercent"
                        :disabled="!isDisabledPaymentPolicySpecial"
                        :rules="[
                          validations.policyNumber(
                            form.PaymentPolicySpecialPercent,
                            isDisabledPaymentPolicySpecial
                          ),
                        ]"
                        outlined
                        dense
                      >
                        <template v-slot:append>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                            </template>
                            <span class="d-block">
                              {{ $t("CAMPUS.PAYMENT_POLICY_SPECIAL_TOOLTIP") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import { UPDATE_COMPANY } from "@/core/services/store/general/company.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_COMPANY,
  GET_COMPANY_CONDITION,
  GET_COMPANY_COMMISSION,
} from "@/core/services/store/general/company.module";
import { mapGetters } from "vuex";
import Terms from "./Terms";
import { VueGooglePlaces } from "vue-google-places";
import Buttons from "@/view/content/components/Buttons";
import Commissions from "@/view/content/components/Commissions";
import Rules from "@/core/services/utils/validations";
import Profile from "@/view/content/components/images/Profile";

export default {
  components: {
    Terms,
    VueGooglePlaces,
    Buttons,
    Commissions,
    Profile,
  },
  data() {
    return {
      key: process.env.VUE_APP_PLACE_KEY,
      conditionsData: null,
      valid: true,
      dialogCommissions: false,
      save_loading: false,
      dialog: false,
      loader: false,
      conditions: false,
      freeCancellationCheck: false,
      freePostponementCancellationCheck: false,
      visaCancellationCheck: false,
      form: {
        id_bussiness: null,
        companyTypeId: null,
        bussinessPrivate: 1,
        commercial_name: null,
        legal_name: null,
        first_name: null,
        last_name: null,
        address: null,
        phone: null,
        email: null,
        websiteUrl: null,
        iban: null,
        bic: null,
        aba: null,
        transition_rules: false,
        transitionRuleTypeId: null,
        cancellationPolicy: null,
        photo: null,
        terms: true,
        addressComponent: null,
        cancellationPoliciesDescription: null,
        freePostponePolicy: null,
        postponePolicy: null,
        PaymentPolicy: false,
        PaymentPolicyLongTerm: null,
        PaymentPolicyPercent: null,
        PaymentPolicySpecial: false,
        PaymentPolicyRecurrence: null,
        PaymentPolicySpecialPercent: null,
        standardCancellationPolicies: null,
        postponeGeneralPolicy: null,
        visaCancellationPolicy: null,
      },
      optionsCompanyType: [
        "",
        this.$t("SETTINGS.USER.BUSSINESS.OPTIONS.OPTION1"),
        this.$t("SETTINGS.USER.BUSSINESS.OPTIONS.OPTION2"),
        this.$t("SETTINGS.USER.BUSSINESS.OPTIONS.OPTION3"),
        this.$t("SETTINGS.USER.BUSSINESS.OPTIONS.OPTION4"),
      ],
      BEFORE_COURSE: 1,
      QUARTERLY_ADVANCE: 2,
      MONTHLY_ADVANCE: 3,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * get info google place for addressComponent
     * @method getAddressData
     * @returns 'addressComponent' object whit data
     */
    getAddressData(addressData) {
      this.form.addressComponent = addressData;
    },

    async onSubmit() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_loading = true;
        let data = this.form;

        const dataImage = await this.$refs.imageProfile.getData();
        if (dataImage.noImage) {
          this.form.photo = null;
        } else {
          this.form.photo = dataImage.url ? dataImage : this.form.photo;
        }

        data = {
          ...data,
          companyPhotoFileId: this.form.photo ? this.form.photo.fileId : null,
          company_photo_file: this.form.photo,
        };

        await this.$store.dispatch(UPDATE_COMPANY, data).then(() => {
          this.save_loading = false;
          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        });
      }
    },

    /**
     * show or hide form information
     */
    PaymentPolicyCheckMethod() {
      this.form.PaymentPolicyLongTerm = this.form.PaymentPolicy
        ? this.company.PaymentPolicyLongTerm
        : null;
      this.form.PaymentPolicyPercent = this.form.PaymentPolicy
        ? this.company.PaymentPolicyPercent
        : null;
      this.form.PaymentPolicyRecurrence = this.form.PaymentPolicy
        ? this.company.PaymentPolicyRecurrence
        : null;
    },

    /**
     * show or hide form information
     */
    freeCancellationCheckMethod() {
      this.form.cancellationPolicy = this.freeCancellationCheck
        ? this.company.cancellationPolicy
        : null;
      this.form.cancellationPoliciesDescription = this.freeCancellationCheck
        ? this.company.cancellationPoliciesDescription
        : null;
    },

    /**
     * show or hide form information
     */
    freePostponementCancellationCheckMethod() {
      this.form.freePostponePolicy = this.freePostponementCancellationCheck
        ? this.company.freePostponePolicy
        : null;
      this.form.postponePolicy = this.freePostponementCancellationCheck
        ? this.company.postponePolicy
        : null;
      this.form.postponeGeneralPolicy = this.freePostponementCancellationCheck
        ? this.company.postponeGeneralPolicy
        : null;
    },

    /**
     * show or hide form information
     */
    PaymentPolicySpecialCheckMethod() {
      this.form.PaymentPolicySpecialPercent = this.form.PaymentPolicySpecial
        ? this.company.PaymentPolicySpecialPercent
        : null;
    },

    /**
     * show or hide form information
     */
    visaCancellationCheckMethod() {
      this.form.visaCancellationPolicy = this.visaCancellationCheck
        ? this.company.visaCancellationPolicy
        : null;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SETTINGS.USER.MAIN.BUSSINESS") },
    ]);
    if (this.company.company_photo_file) {
      this.form.photo = this.company.company_photo_file;
      this.$refs.imageProfile.setData(this.form.photo);
    }
  },
  created() {
    const { user } = this.currentUser;
    this.$store.dispatch(GET_COMPANY_CONDITION);

    const json = {
      companyId: user.companyId,
      auth: true,
    };

    this.$store.dispatch(GET_COMPANY_COMMISSION, json);

    if (user.companyId) {
      !this.company.name && this.$store.dispatch(GET_COMPANY, user.companyId);
    }

    this.form = this.company;
    const {
      cancellationPolicy,
      cancellationPoliciesDescription,
      freePostponePolicy,
      postponePolicy,
      postponeGeneralPolicy,
      visaCancellationPolicy,
    } = this.company;

    // CHECK freeCancellationCheck
    if (cancellationPolicy || cancellationPoliciesDescription) {
      this.freeCancellationCheck = true;
    }

    // CHECK freePostponementCancellationCheck
    if (freePostponePolicy || postponePolicy || postponeGeneralPolicy) {
      this.freePostponementCancellationCheck = true;
    }

    // CHECK visaCancellationCheck
    if (visaCancellationPolicy) {
      this.visaCancellationCheck = true;
    }

    this.form.addressComponent = null;
  },
  computed: {
    ...mapGetters([
      "campuses",
      "campus",
      "company",
      "currentUser",
      "CompanyCondition",
      "CompanyCommission",
    ]),
    isDisabled() {
      const { user } = this.currentUser;
      if (user.companyId === null) {
        return true;
      }
      return false;
    },
    isLoader() {
      return this.loader;
    },
    isSave() {
      if (this.conditions) {
        return false;
      }
      return true;
    },
    formatDate() {
      const { date_format } = this.campus;
      return this.$moment(String(this.company.created_at)).format(date_format);
    },
    generalConditions() {
      let flag = false;
      if (this.form.bussinessPrivate === 1) {
        if (this.form.commercial_name !== "" && this.form.legal_name !== "") {
          flag = true;
        }
      } else {
        if (this.form.first_name !== "" && this.form.last_name !== "") {
          flag = true;
        }
      }
      if (
        this.form.id_bussiness === "" ||
        this.form.address === "" ||
        this.form.phone === "" ||
        this.form.email === "" ||
        this.form.websiteUrl === "" ||
        !flag
      ) {
        return false;
      }
      return true;
    },
    isDisabledPaymentPolicy() {
      return this.form.PaymentPolicy;
    },
    isDisabledPaymentPolicySpecial() {
      return this.form.PaymentPolicySpecial;
    },
    isDisabledFree() {
      return this.freeCancellationCheck;
    },
    isDisabledVisa() {
      return this.visaCancellationCheck;
    },
    isDisabledFreePostponement() {
      return this.freePostponementCancellationCheck;
    },
  },
};
</script>

<style scoped>
.info-payment {
  background-color: #f6f6f6;
  padding: 10px;
  color: #534d4d;
  font-style: italic;
  border: 1px solid #e6dfdf;
}

.font-size-1-2 {
  font-size: 1.2rem;
}
.font-weight-b {
  font-weight: 100;
}
</style>
