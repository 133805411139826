<template>
  <div v-html="conditions"></div>
</template>
<script>
export default {
  props: {
    conditions: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
p {
  font-size: 1.3rem;
}
h1 {
  margin-top: 10px !important;
}

li {
  font-size: 1.3rem;
  padding: 5px;
  text-align: justify;
  margin-left: 1.2em;
}

p span strong {
  font-size: 1.5rem;
}
</style>
